@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;0,900;1,400;1,900&display=swap");
html,
body {
  height: 100%;
  margin: 0;
  font-family: 'Lato', 'sans-serif';
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 2rem;
}
body {
  background-color: #062235;
  background-image: url("images/steinheide-bg.png");
  background-repeat: no-repeat;
  background-position: center bottom;
  background-attachment: fixed;
  background-size: cover;
  min-height: 100vh;
  color: #fff;
  margin: 0;
  padding: 0;
}
.big-text {
  font-size: 24px;
  line-height: 32px;
}
@media (max-width: 576px) {
  .big-text {
    font-size: 20px;
    line-height: 28px;
  }
}
header {
  background-color: #061f33;
  box-shadow: 0px 4px 4px rgba(0,0,0,0.25);
}
header img {
  width: 4.375rem;
  height: 4.375rem;
  margin: 0.3125rem 2.5rem;
}
header .back {
  float: right;
  margin-bottom: 0;
  line-height: 89px;
}
#root {
  min-height: 100%;
  margin-bottom: -4.375rem;
}
@media (max-width: 576px) {
  #root {
    padding-left: 10px;
    padding-right: 10px;
  }
}
.footer,
.push {
  height: 4.375rem;
}
.footer span,
.push span {
  padding: 0 0.625rem;
}
h1 {
  font-weight: 900;
  font-size: 3rem;
  line-height: 4rem;
  text-align: center;
  letter-spacing: 0.02em;
  margin-bottom: 2.8125rem;
  margin-top: 2.8125rem;
}
@media (max-width: 576px) {
  h1 {
    font-size: 36px;
    line-height: 44px;
  }
}
h2.bigger {
  font-size: 36px;
  line-height: 44px;
  font-weight: 900;
}
@media (max-width: 576px) {
  h2.bigger {
    font-size: 24px;
    line-height: 32px;
  }
}
a {
  color: #e5ba74;
  text-decoration: underline;
}
a:hover {
  color: #da9d3a;
}
button,
.form .file label.button {
  font-family: 'Lato', 'sans-serif';
  background-color: #e5ba74;
  color: #fff;
  border-radius: 0.25rem;
  min-height: 2.5rem;
  line-height: 2.5rem;
  min-width: 17.5rem;
  font-weight: 900;
  font-size: 1rem;
  text-align: center;
  border: none;
  margin-bottom: 1.5rem;
  text-shadow: 0px 1px 2px rgba(0,0,0,0.25);
  text-transform: uppercase;
  cursor: pointer;
}
button:focus,
.form .file label.button:focus,
button :active,
.form .file label.button :active {
  outline: none;
}
button:disabled,
.form .file label.button:disabled {
  cursor: not-allowed;
  opacity: 0.35;
}
button:hover,
.form .file label.button:hover {
  background-color: #e0ac57;
}
@media (max-width: 576px) {
  button,
  .form .file label.button {
    max-width: 80%;
    min-width: 80%;
  }
}
.center {
  text-align: center;
}
button.order-button {
  margin-bottom: 2.5rem;
}
.order-list-wrapper {
  margin-top: 0.9375rem;
}
.order-success {
  max-width: 500px;
  margin: 0 auto;
}
.order-success p {
  margin-bottom: 1.875rem;
}
.order-success h1 {
  color: #e5ba74;
}
.form {
  background-color: #fff;
  border-radius: 4px;
  margin: 15px auto;
  max-width: 850px;
  color: #000;
  padding: 2.8125rem 1.875rem;
  font-family: 'Lato', 'sans-serif';
}
.form h2 {
  color: #061f33;
  font-weight: 900;
  text-transform: uppercase;
  font-size: 1rem;
  line-height: 2rem;
  letter-spacing: 0.08em;
  margin-top: 1.25rem;
  margin-bottom: 0.75rem;
}
.form fieldset {
  margin: 0;
  padding: 0;
  border: 0;
}
.form .field-error {
  border-bottom: 1px solid #e4e4e4;
  padding-bottom: 1.5625rem;
  color: #e5ba74;
  margin-top: 0.3125rem;
}
.form .field-error.all {
  border-bottom: none;
}
.form .field-wrapper:last-of-type .field-error {
  border-bottom: none;
}
.form fieldset.radios .radio {
  display: inline-block;
}
.form fieldset.radios .radio button {
  border: 1px solid #cfe2f1;
}
.form fieldset.radios .radio input {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  height: 1px;
  width: 1px;
  display: inline;
  margin: 0px;
  padding: 0px;
}
.form fieldset.radios .radio input:checked + button {
  background-color: #335b75;
  color: #fff;
  border-color: #335b75;
}
.form fieldset.radios .radio label {
  width: 100%;
  display: inline-block;
  padding: 0 20px;
  cursor: pointer;
  font-family: Lato, sans-serif;
  font-weight: 700;
}
.form fieldset.radios .radio:first-of-type button {
  border-top-left-radius: 24px;
  border-bottom-left-radius: 24px;
}
.form fieldset.radios .radio:last-of-type button {
  border-top-right-radius: 24px;
  border-bottom-right-radius: 24px;
}
@media (max-width: 576px) {
  .form fieldset.radios .radio {
    display: inline;
  }
  .form fieldset.radios .radio button {
    display: inline-block;
    margin-bottom: 10px;
  }
}
.form .field-wrapper button {
  background-color: transparent;
  color: #7a8084;
  border-radius: 0;
  padding: 0;
  min-height: 2.5rem;
  line-height: 2.5rem;
  min-width: 6.25rem;
  margin-bottom: 0;
  text-shadow: none;
  font-weight: bold;
}
.form button.form-continue {
  min-width: 180px;
  box-shadow: 0px 2px 6px #ccc;
}
.form textarea {
  width: 24.5rem;
  max-width: 100%;
  min-height: 6.875rem;
  border-radius: 4px;
  border: 1px solid #e4e4e4;
  padding: 0.5rem 1rem;
  font-family: Lato, sans-serif;
  margin-top: 1.5rem;
  margin-bottom: 2.5rem;
  font-size: 1.125rem;
  line-height: 2rem;
}
.form textarea::-webkit-input-placeholder {
  color: #818a91;
}
.form textarea:-ms-input-placeholder {
  color: #818a91;
}
.form textarea::-ms-input-placeholder {
  color: #818a91;
}
.form textarea::placeholder {
  color: #818a91;
}
.form .file {
  width: 24.5rem;
  max-width: 100%;
  border-radius: 4px;
  border: 1px solid #e4e4e4;
  margin: 0.5rem auto;
  background-color: #f8f8f8;
  padding: 1rem;
}
.form .file label.button {
  display: inline-block;
  min-width: 180px;
  margin-top: 10px;
  box-shadow: 0px 2px 6px #ccc;
}
.form .file .desc {
  margin-bottom: 10px;
}
.form .file .file-progress {
  color: #818a91;
}
.preview h2 {
  margin-bottom: 1.5rem;
}
.preview .fields {
  text-align: left;
  font-size: 1.125rem;
  line-height: 2rem;
  max-width: 500px;
  margin: 0 auto;
  border-bottom: 1px solid #e4e4e4;
  border-top: 1px solid #e4e4e4;
  padding-bottom: 3.75rem;
  margin-top: 1.5rem;
}
.preview .fields .field-preview-title {
  margin-top: 1.25rem;
}
.preview .consent {
  margin-top: 1.5rem;
  margin-bottom: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.preview .consent input {
  margin-right: 0.625rem;
  width: 2rem;
  height: 2rem;
  background-color: #f8f8f8;
  border: 1px solid #e4e4e4;
}
.back {
  display: inline-block;
}
.back button {
  color: #e5ba74;
  background: none;
  border: none;
  text-shadow: none;
  min-width: auto;
  margin-right: 60px;
}
.back button:hover {
  color: #da9d3a;
}
input[type=text],
input[type=email],
select {
  font-family: 'Lato', 'sans-serif';
  background-color: #fff;
  border: 1px solid #e4e4e4;
  border-radius: 0.25rem;
  color: #000;
  font-size: 1.125rem;
  font-weight: 400;
  min-height: 1.625rem;
  padding: 0.5rem 1rem;
  border: none;
  margin-bottom: 1.5rem;
}
input[type=text]::-webkit-input-placeholder,
input[type=email]::-webkit-input-placeholder,
select::-webkit-input-placeholder {
  color: #818a91;
}
input[type=text]:-ms-input-placeholder,
input[type=email]:-ms-input-placeholder,
select:-ms-input-placeholder {
  color: #818a91;
}
input[type=text]::-ms-input-placeholder,
input[type=email]::-ms-input-placeholder,
select::-ms-input-placeholder {
  color: #818a91;
}
input[type=text]::placeholder,
input[type=email]::placeholder,
select::placeholder {
  color: #818a91;
}
input[type=text]:focus,
input[type=email]:focus,
select:focus,
input[type=text] :active,
input[type=email] :active,
select :active {
  outline: none;
}
@media (max-width: 576px) {
  input[type=text],
  input[type=email],
  select {
    max-width: 80%;
    min-width: 80%;
  }
}
.login {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  max-width: 31.25rem;
}
.login input {
  width: 17.5rem;
}
.login-text {
  margin-bottom: 2.5rem;
}
* {
  box-sizing: border-box;
}
.rc-virtual-list {
  border: 1px solid #cfe2f1;
  border-top: 0px;
  border-radius: 4px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  padding: 2px;
}
.rc-select {
  display: inline-block;
  font-size: 12px;
  width: 100%;
  max-width: 500px;
  position: relative;
  border: 1px solid #cfe2f1;
  border-radius: 4px;
  padding: 0.125rem;
}
.rc-select-disabled,
.rc-select-disabled input {
  cursor: not-allowed;
}
.rc-select-disabled .rc-select-selector {
  opacity: 0.3;
}
.rc-select-show-arrow.rc-select-loading .rc-select-arrow-icon::after {
  box-sizing: border-box;
  width: 12px;
  height: 12px;
  border-radius: 100%;
  border: 2px solid #999;
  border-top-color: transparent;
  border-bottom-color: transparent;
  transform: none;
  margin-top: 4px;
  animation: rcSelectLoadingIcon 0.5s infinite;
}
.rc-select .rc-select-selection-placeholder {
  opacity: 0.4;
  color: #5d6d7a;
}
.rc-select .rc-select-selection-search-input {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}
.rc-select .rc-select-selection-search-input::-webkit-search-cancel-button {
  display: none;
  -webkit-appearance: none;
          appearance: none;
}
.rc-select-single .rc-select-selector {
  display: flex;
  position: relative;
}
.rc-select-single .rc-select-selector .rc-select-selection-search {
  width: 90%;
  text-align: left;
  font-size: 1.125rem;
}
.rc-select-single .rc-select-selector .rc-select-selection-search-input {
  width: 100%;
  font-size: 1.125rem;
}
.rc-select-single .rc-select-selector .rc-select-selection-item,
.rc-select-single .rc-select-selector .rc-select-selection-placeholder {
  position: absolute;
  top: 1px;
  left: 3px;
  pointer-events: none;
  padding: 5px 13px;
  font-size: 1.125rem;
}
.rc-select-single:not(.rc-select-customize-input) .rc-select-selector {
  padding: 1px;
  background: #fff;
  color: #000;
}
.rc-select-single:not(.rc-select-customize-input) .rc-select-selector .rc-select-selection-search-input {
  border: none;
  outline: none;
  height: 40px;
  padding: 5px 13px;
}
.rc-select-multiple .rc-select-selector {
  display: flex;
  flex-wrap: wrap;
  padding: 1px;
  border: 1px solid #000;
}
.rc-select-multiple .rc-select-selector .rc-select-selection-item {
  flex: none;
  background: #bbb;
  border-radius: 4px;
  margin-right: 2px;
  padding: 0 8px;
}
.rc-select-multiple .rc-select-selector .rc-select-selection-item-disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
.rc-select-multiple .rc-select-selector .rc-select-selection-search {
  position: relative;
}
.rc-select-multiple .rc-select-selector .rc-select-selection-search-input,
.rc-select-multiple .rc-select-selector .rc-select-selection-search-mirror {
  padding: 1px;
  font-family: system-ui;
}
.rc-select-multiple .rc-select-selector .rc-select-selection-search-mirror {
  position: absolute;
  z-index: 999;
  white-space: nowrap;
  position: none;
  left: 0;
  top: 0;
  visibility: hidden;
}
.rc-select-multiple .rc-select-selector .rc-select-selection-search-input {
  border: none;
  outline: none;
  height: 40px;
  padding: 5px 13px;
}
.rc-select-allow-clear.rc-select-multiple .rc-select-selector {
  padding-right: 20px;
}
.rc-select-allow-clear .rc-select-clear {
  position: absolute;
  right: 2.1875rem;
  top: 0;
  color: #e5ba74;
  line-height: 2.5rem;
  font-size: 1.5rem;
}
.rc-select-show-arrow.rc-select-multiple .rc-select-selector {
  padding-right: 20px;
}
.rc-select-show-arrow .rc-select-arrow {
  pointer-events: none;
  position: absolute;
  right: 16px;
  top: 0;
  line-height: 2.5625rem;
}
.rc-select-show-arrow .rc-select-arrow-icon::after {
  content: '';
  border: 6px solid transparent;
  width: 0;
  height: 0;
  display: inline-block;
  border-top-color: #818a91;
  transform: translateY(6px);
}
.rc-select-focused .rc-select-selector {
  border-color: #00f !important;
}
.rc-select-dropdown {
  border: 1px solid #fff;
  min-height: 100px;
  position: absolute;
  background: #fff;
}
.rc-select-dropdown-hidden {
  display: none;
}
.rc-select-item {
  font-size: 16px;
  line-height: 1.5;
  padding: 4px 16px;
  color: #000;
}
.rc-select-item-group {
  color: #999;
  font-weight: bold;
  font-size: 80%;
}
.rc-select-item-option {
  position: relative;
}
.rc-select-item-option-grouped {
  padding-left: 24px;
}
.rc-select-item-option .rc-select-item-option-state {
  position: absolute;
  right: 0;
  top: 4px;
  pointer-events: none;
}
.rc-select-item-option-active {
  background: #f8f8f8;
}
.rc-select-item-option-disabled {
  color: #999;
}
.rc-select-item-empty {
  text-align: center;
  color: #999;
}
.rc-select-selection__choice-zoom {
  transition: all 0.3s;
}
.rc-select-selection__choice-zoom-appear {
  opacity: 0;
  transform: scale(0.5);
}
.rc-select-selection__choice-zoom-appear.rc-select-selection__choice-zoom-appear-active {
  opacity: 1;
  transform: scale(1);
}
.rc-select-selection__choice-zoom-leave {
  opacity: 1;
  transform: scale(1);
}
.rc-select-selection__choice-zoom-leave.rc-select-selection__choice-zoom-leave-active {
  opacity: 0;
  transform: scale(0.5);
}
.rc-select-dropdown-slide-up-enter,
.rc-select-dropdown-slide-up-appear {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  transform-origin: 0 0;
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  animation-play-state: paused;
}
.rc-select-dropdown-slide-up-leave {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  transform-origin: 0 0;
  opacity: 1;
  animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
  animation-play-state: paused;
}
.rc-select-dropdown-slide-up-enter.rc-select-dropdown-slide-up-enter-active.rc-select-dropdown-placement-bottomLeft,
.rc-select-dropdown-slide-up-appear.rc-select-dropdown-slide-up-appear-active.rc-select-dropdown-placement-bottomLeft {
  animation-name: rcSelectDropdownSlideUpIn;
  animation-play-state: running;
}
.rc-select-dropdown-slide-up-leave.rc-select-dropdown-slide-up-leave-active.rc-select-dropdown-placement-bottomLeft {
  animation-name: rcSelectDropdownSlideUpOut;
  animation-play-state: running;
}
.rc-select-dropdown-slide-up-enter.rc-select-dropdown-slide-up-enter-active.rc-select-dropdown-placement-topLeft,
.rc-select-dropdown-slide-up-appear.rc-select-dropdown-slide-up-appear-active.rc-select-dropdown-placement-topLeft {
  animation-name: rcSelectDropdownSlideDownIn;
  animation-play-state: running;
}
.rc-select-dropdown-slide-up-leave.rc-select-dropdown-slide-up-leave-active.rc-select-dropdown-placement-topLeft {
  animation-name: rcSelectDropdownSlideDownOut;
  animation-play-state: running;
}
@keyframes rcSelectDropdownSlideUpIn {
  0% {
    opacity: 0;
    transform-origin: 0% 0%;
    transform: scaleY(0);
  }
  100% {
    opacity: 1;
    transform-origin: 0% 0%;
    transform: scaleY(1);
  }
}
@keyframes rcSelectDropdownSlideUpOut {
  0% {
    opacity: 1;
    transform-origin: 0% 0%;
    transform: scaleY(1);
  }
  100% {
    opacity: 0;
    transform-origin: 0% 0%;
    transform: scaleY(0);
  }
}
@keyframes rcSelectLoadingIcon {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
